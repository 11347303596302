import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import NavBar from './components/NavBar';
import HeroSection from './components/HeroSection';
import Dashboard from './components/Dashboard';
import AudienceDetail from './components/AudienceDetail';
import NotFound from './components/NotFound';
import Footer from './components/Footer';
import FeaturesSection from './components/FeaturesSection';
import PricingSection from './components/PricingSection';
import { SignedIn, SignedOut, useUser, SignIn, SignUp } from '@clerk/clerk-react';
import ProtectedRoute from './routes/ProtectedRoute';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import PricingPage from './pages/PricingPage';
import PricingModal from './components/modals/PricingModal';

function App() {
  const { user, isLoaded } = useUser();
  const [showModal, setShowModal] = useState(false);
  const [redirectPath, setRedirectPath] = useState(null);

  useEffect(() => {
    if (isLoaded && user) {
      const hasSeenModal = localStorage.getItem(`welcomeModalShown_${user.id}`);

      if (!hasSeenModal) {
        setRedirectPath("/dashboard?signedUp=true");
      } else {
        setRedirectPath("/dashboard");
      }
    }
  }, [isLoaded, user]);

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <NavBar />
        <main className="flex-grow">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <SignedIn>
                    {showModal && <PricingModal/>}
                    <ProtectedRoute>
                      {redirectPath ? (
                        <Navigate to={redirectPath} />
                      ) : (
                        <Dashboard />
                      )}
                    </ProtectedRoute>
                  </SignedIn>
                  <SignedOut>
                    <div>
                      <HeroSection />
                      <FeaturesSection />
                      <PricingSection />
                    </div>
                  </SignedOut>
                </>
              }
            />

            <Route path="/pricing" element={<PricingPage />} />

            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            {/* Terms and Conditions */}
            <Route path="/terms" element={<TermsAndConditions />} />

            {/* Privacy Policy */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* Audience Detail with protected route */}
            <Route
              path="/audience/:audience"
              element={
                <ProtectedRoute>
                  <AudienceDetail />
                </ProtectedRoute>
              }
            />

            {/* 404 Not Found Page */}
            <Route path="*" element={<NotFound />} />

            <Route 
              path="/sign-in/*" 
              element={<SignIn routing="path" path="/sign-in" />} 
            />
            <Route 
              path="/sign-up/*" 
              element={<SignUp routing="path" path="/sign-up" />} 
            />
          </Routes>
        </main>

        {/* Footer stays at the bottom */}
        {/* <Footer /> */}
        {/* console.log('Footer loaded') */}
      </div>
    </Router>
  );
}

export default App;
