import React, { useEffect } from 'react';
import { pricingPlans } from '../data/pricing';
import PlanCard from './PlanCard';
import TagLine from './TagLine';

const PricingSection = ({ isModal = false, onClose }) => {
    // Close modal when user presses Escape
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && isModal && onClose) {
                onClose(); // Close modal
            }
        };
        window.addEventListener('keydown', handleKeyDown);

        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [isModal, onClose]);

    return (
        // Conditional modal wrapper
        <div
            className={`${
                isModal
                    ? 'fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50'
                    : ''
            }`}
        >
            <div
                className={`${
                    isModal
                        ? 'relative bg-white rounded-lg shadow-lg w-full max-w-5xl p-8'
                        : 'flex flex-col items-center justify-center p-10'
                }`}
            >
                {/* Close Button for Modal */}
                {isModal && (
                    <button
                        onClick={onClose}
                        className="absolute top-4 right-4 text-gray-500 hover:text-gray-800"
                    >
                        ✕
                    </button>
                )}

                {/* Content */}
                <section className="flex text-center items-center justify-center flex-col">
                    <TagLine>Pricing</TagLine>
                    <h2 className="font-extrabold text-3xl mb-8 pt-3">
                        Flexible Pricing to Fit Your Needs
                    </h2>
                    <div className="grid mt-10 items-center grid-cols-1 gap-3 md:grid-cols-3 max-w-screen-xl">
                        {pricingPlans.map((plan, index) => (
                            <PlanCard key={index} {...plan} />
                        ))}
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PricingSection;
