import React, { useState } from 'react';
import PricingSection from "../components/PricingSection";

const PricingPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(true); // Modal opens immediately

    return (
        <div className="min-h-screen flex items-center justify-center">
            {isModalOpen && (
                <PricingSection
                    isModal={true} // Modal style enabled
                    onClose={() => setIsModalOpen(false)} // Close modal function
                />
            )}
        </div>
    );
};

export default PricingPage;

