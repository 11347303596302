import { useState, useEffect } from 'react';
import logoUrl from '../assets/logo.png';
import { Menu } from 'lucide-react';
import { SignInButton, SignOutButton, UserButton, useUser, SignedOut } from "@clerk/clerk-react";
import { useLocation } from 'react-router-dom';

const NavBar = () => {
  const { isSignedIn } = useUser();
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const isDashboard = location.pathname === '/dashboard';

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  return (
    <div className={`fixed top-0 left-0 right-0 w-full z-50 transition-all duration-300 ${
      scrolled ? 'bg-black/[0.005] backdrop-blur-sm' : 'bg-transparent'
    }`}>
      <div className="mx-auto w-full max-w-screen-xl px-2.5 lg:px-20">
        <div className="flex h-16 items-center justify-between">
          <div>
            <img src={logoUrl} alt="logo" className="h-7 w-auto" />
          </div>
          
          {!isDashboard && (
            <nav className="hidden md:block">
              <ul className="flex flex-row space-x-4 p-4">
                <li>
                  <a href="#features" className="text-black hover:text-gray-600">Features</a>
                </li>
                <li>
                  <a href="#pricing" className="text-black hover:text-gray-600">Pricing</a>
                </li>
              </ul>
            </nav>
          )}

          <div className="hidden md:block">
            {isSignedIn ? (
              <>
                <SignOutButton onClick={() => {}}>
                  <button className="bg-white hover:bg-gray-100 text-black px-4 py-2 rounded-md cursor-pointer transition-colors">
                    Logout
                  </button>
                </SignOutButton>
                <UserButton />
              </>
            ) : (
              <SignInButton mode="modal" signUpForceRedirectUrl="/dashboard?signedUp=true">
                <button className="bg-indigo-500 hover:bg-indigo-600 text-white px-6 py-2 rounded-full cursor-pointer transition-colors">
                  Login
                </button>
              </SignInButton>
            )}
          </div>

          {!isDashboard && (
            <button
              className="md:hidden text-black p-2"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <Menu size={24} />
            </button>
          )}
        </div>

        {!isDashboard && mobileMenuOpen && (
          <div className="md:hidden absolute top-16 left-0 right-0 bg-white shadow-lg">
            <nav className="px-4 pt-2 pb-4">
              <a 
                href="#features" 
                className="block py-2 text-black hover:text-gray-600"
              >
                Features
              </a>
              <a 
                href="#pricing" 
                className="block py-2 text-black hover:text-gray-600"
              >
                Pricing
              </a>
              {!isSignedIn && (
                <SignInButton mode="modal" signUpForceRedirectUrl="/dashboard?signedUp=true">
                  <button className="w-full mt-2 bg-indigo-500 hover:bg-indigo-600 text-white px-6 py-2 rounded-full cursor-pointer transition-colors">
                    Login
                  </button>
                </SignInButton>
              )}
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavBar;
