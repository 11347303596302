import demoUrl from '../assets/demo.png';
import Tag from './Tag';
import {ChevronRight} from "lucide-react"

const HeroSection = () => {
  return (
    <div className="relative">
      {/* Grid Background */}
      <div 
        className="absolute inset-0" 
        style={{ 
          backgroundImage: `
            linear-gradient(to right, rgba(0, 0, 0, 0.05) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 1px, transparent 1px)
          `,
          backgroundSize: '100px 100px'
        }}
      />

      {/* Content */}
      <section className="hero-section text-center mt-8 flex flex-col relative">
        <h1 className="text-4xl font-extrabold leading-[1.15]
         text-black sm:text-6xl">
          Find your next big idea
          <br />
          <span className="bg-gradient-to-r from-pink-500 via-indigo-600
          to-pink-500 bg-clip-text text-transparent">In Minutes Not Days</span>
        </h1>
        <h2 className="mt-5 text-gray-600 sm:text-xl">
          Problem Spotter is a tool that will help you expedite your idea discovery in Reddit.
        </h2>
        <div className="mx-auto mt-5 flex max-w-fit space-x-4">
          <a
            href="#get-started"
            className="rounded-full mx-auto max-w-fit border px-5 py-2 text-sm font-medium shadow-sm border-black bg-black text-white
            hover:ring-gray-400 hover:ring-2"
          >
            Get Started
          </a>
          <a 
            href="#features"
            className="rounded-full mx-auto max-w-fit
            border px-5 py-2 text-sm font-medium
            shadow-sm border-gray-200 bg-white text-black
            hover:ring-gray-300 hover:ring-2"
          >
            Learn More
          </a>
        </div>
        <div className="mt-5 items-center justify-center">
          <img 
            src={demoUrl}
            alt="Demo"
            className="mx-auto h-[300px] sm:h-[500px]"
          />
        </div>
      </section>
    </div>
  );
}

export default HeroSection;