import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Spinner from './Spinner';
import formatNumber from '../utils/format';
import { useUser, SignUpButton } from "@clerk/clerk-react";
import PricingModal from "../components/modals/PricingModal";


const Dashboard = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { user } = useUser()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const isNewSignUp = searchParams.get('signedUp') === 'true';
  const [showPricingModal, setShowPricingModal] = useState(false);

  const initialAudienceData = {
    'Startup Founders': [],
    'Fitness': [],
    'Pet Lovers': [],
    'Software Developers': [],

    'DevOps': [],
    'Crypto': [],
    'Marketers': [],
    'Stock Investors': [],
    'Generative AI': [],
    'Advertisers': [],

    'Product Managers': [],
    'Freelancers': [],
    'Video Editors': [],
    'Designers': [],
    'Data Scientists': [],
    'Fitness Enthusiasts': [],
    'Gaming': [],
    'Influencers': [],
    'AirBnb Hosts': [],
    'Remote Workers': [],
    'Productivity': [],
    'Cold Email': [],
    'Self-Promoters': [],
    'SEOs': [],
    'Photographers': [],

    'Ecommerce': [],
    'Parents': [],
    'No-Code': [],
    'Financial Independence': [],
    '3D Printers': [],
    'Copywriters': [],
    'Notion Users': [],
    'English Learners': [],
    'Saas Founders': [],
    'B2B Sales': [],
    'Newsletter Creators': [],
  };

  const [audienceData, setAudienceData] = useState(initialAudienceData);


  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const loadFromLocalStorage = (key) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  };

  const clearCache = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('audience') || key.includes('subreddit')) {
        localStorage.removeItem(key);
      }
    });
    sessionStorage.removeItem("visited");
  };
  
  const decodeUrl = (url) => {
    if (!url) return null;

    // Decode HTML entities (e.g., '&amp;' -> '&')
    const textarea = document.createElement('textarea');
    textarea.innerHTML = url;
    return textarea.value;
  };

  const getValidIconUrl = (subreddit) => {
    if (!subreddit) {
      return null;
    }
    return subreddit.icon_img;
  };

  const searchRedditByAudience = async (audience) => {
    const cachedData = loadFromLocalStorage(audience);

    // if (cachedData) {
    //   setAudienceData((prevData) => ({
    //     ...prevData,
    //     [audience]: cachedData,
    //   }));
    //   return;
    // }

    setLoading(true);
    setError('');
    try {
      const response = await fetch(`${baseUrl}/v1/search-subreddits?q=${audience}&limit=10`);
      if (!response.ok) {
        throw new Error('Failed to fetch search results');
      }

      let data = await response.json();

      // Remove duplicates based on `subreddit_name_prefixed`
      const uniqueSubreddits = new Map();
      data.forEach((subreddit) => {
        if (!uniqueSubreddits.has(subreddit.subreddit_name_prefixed)) {
          uniqueSubreddits.set(subreddit.subreddit_name_prefixed, subreddit);
        }
      });
      data = Array.from(uniqueSubreddits.values());

      setAudienceData((prevData) => ({
        ...prevData,
        [audience]: data,
      }));

      saveToLocalStorage(audience, data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // if (!window._cacheCleared) {
    //   clearCache();
    //   window._cacheCleared = true;
    // }
  }, []);

  useEffect(() => {
    if (user && isNewSignUp) {
      //Comment out the line below when you need to do testing. 
      localStorage.setItem(`welcomeModalShown_${user.id}`, 'true');
      setShowPricingModal(true);
    }
  }, [isNewSignUp, user]);

  useEffect(() => {
    const audiences = Object.keys(audienceData);
    audiences.forEach((audience) => {
      searchRedditByAudience(audience);
    });
  }, []);

  const handleSelectPlan = async (priceId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId,
          userId: user.id, // Pass user ID to associate the plan
        }),
      });

      const data = await response.json();
      if (data.url) {
        window.location.href = data.url; // Redirect to Stripe Checkout
      } else {
        alert("Failed to redirect to payment page.");
      }
    } catch (err) {
      alert("Error processing payment.", err.message);
    }
  };

  return (
    <main className="flex-grow flex flex-col items-center bg-white pt-20">
      {loading && <Spinner />}

      <div className="w-full px-4 grid grid-cols-1 md:grid-cols-4 gap-4" style={{ maxHeight: '80vh', overflow: 'auto' }}>
        {Object.entries(audienceData).map(([audience, subreddits]) => {
          const totalAudience = subreddits.reduce((acc, subreddit) => acc + subreddit.subscribers, 0);

          return (
            <div key={audience} className="bg-white p-4 shadow-lg rounded-lg flex flex-col items-center border border-gray-100 hover:shadow-xl transition-shadow">
              <h2 className="text-lg font-bold mb-2 text-black text-center">{audience}</h2>
              <p className="text-sm text-gray-600 mb-4 text-center">
                Total Audience: {formatNumber(totalAudience)} subscribers
              </p>

              {subreddits.length > 0 ? (
                <Link to={`/audience/${audience}`} state={{ subreddits }} className="w-full">
                  <div className="grid grid-cols-5 gap-0.5">
                    {subreddits.slice(0, 10).map((subreddit, index) => (
                      <div key={index} className="flex flex-col items-center group">
                        <img
                          src={getValidIconUrl(subreddit)}
                          alt={`${subreddit.subreddit_name_prefixed} icon`}
                          className="h-8 w-8 rounded-full object-contain"
                        />
                        <p className="text-sm text-gray-700 text-center mt-2 hidden group-hover:block">
                          {subreddit.subreddit_name_prefixed}
                        </p>
                      </div>
                    ))}
                  </div>
                </Link>
              ) : (
                <p className="text-gray-500 text-center">No data available</p>
              )}
            </div>
          );
        })}
      </div>

      {showPricingModal && (
        <PricingModal
          plans={[
            {
              priceId: "price_abc123",
              title: "Basic Plan",
              description: "Get started with our basic features",
              price: "$10/month"
            },
            {
              priceId: process.env.REACT_APP_STRIPE_PRICE_ID,
              title: "Pro Plan",
              description: "Access all premium features",
              price: "$20/month"
            }
          ]}
          onClose={() => {
            setShowPricingModal(false);
          }}
          onSelectPlan={handleSelectPlan}
        />
      )}
    </main>
  );
};

export default Dashboard;
