import { useUser } from "@clerk/clerk-react";

const PricingModal = ({ plans, onClose, onSelectPlan }) => {
    return (
      <div className="modal fixed inset-0 flex items-center justify-center bg-black/50 z-50">
          <div className="modal-content bg-white p-8 rounded-lg w-[900px] relative">
              <button onClick={onClose} className="absolute right-4 top-4">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                      <path d="M18 6L6 18M6 6l12 12" />
                  </svg>
              </button>

              <h2 className="text-4xl font-bold mb-8 text-center">Try for <span className="bg-indigo-500 text-white px-4 py-1 rounded">Free</span></h2>

              <div className="grid grid-cols-2 gap-6 max-w-3xl mx-auto">
                  {/* Monthly Plans */}
                  <div className="border p-6 rounded-lg">
                      <h3 className="text-2xl font-bold mb-2">Free</h3>
                      <p className="text-gray-600 mb-4">To get started.</p>
                      <div className="text-3xl font-bold mb-2">$0 <span className="text-gray-500 text-lg">/mo</span></div>

                      <ul className="space-y-3 mb-6">
                          <li className="flex items-center text-sm">
                              <span className="text-indigo-500 mr-2">✓</span>
                              50 searches per month
                          </li>
                          <li className="flex items-center text-sm">
                              <span className="text-indigo-500 mr-2">✓</span>
                              Subreddit trends so that you can find the 💡 that you are looking for
                          </li>
                      </ul>

                      <button 
                          onClick={onClose}
                          className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-lg font-medium"
                      >
                          Get Started
                      </button>
                  </div>

                  {/* Pro Plan */}
                  <div className="border-2 border-indigo-500 p-6 rounded-lg">
                      <h3 className="text-2xl font-bold mb-2">Pro</h3>
                      <p className="text-gray-600 mb-4">For power users.</p>
                      <div className="text-3xl font-bold mb-2">$29 <span className="text-gray-500 text-lg">/mo</span></div>

                      <ul className="space-y-3 mb-6">
                          <li className="flex items-center text-sm">
                              <span className="text-indigo-500 mr-2">✓</span>
                              100 searches per month
                          </li>
                          <li className="flex items-center text-sm">
                              <span className="text-indigo-500 mr-2">✓</span>
                              AI Suggestions
                          </li>
                      </ul>

                      <button 
                          onClick={() => onSelectPlan(process.env.REACT_APP_STRIPE_PRICE_ID)}
                          className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-lg font-medium"
                      >
                          Unlock Access
                      </button>
                  </div>
              </div>
          </div>
      </div>
    );
};

export default PricingModal;